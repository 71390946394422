import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NiceSelectModule } from 'ng-nice-select';
import { FormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CanvasComponent } from './canvas/canvas.component';
import { BlogSidebarComponent } from './blog-sidebar/blog-sidebar.component';
import { InnerNewsletterComponent } from './inner-newsletter/inner-newsletter.component';
import { SingleTestimonialsComponent } from './single-testimonials/single-testimonials.component';
import { CookiesComponent } from './cookies/cookies.component';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SearchBoxComponent,
    BreadcrumbComponent,
    CanvasComponent,
    BlogSidebarComponent,
    InnerNewsletterComponent,
    SingleTestimonialsComponent,
    CookiesComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        BreadcrumbModule,
        NiceSelectModule,
        FormsModule,
        SlickCarouselModule,
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        BreadcrumbComponent,
        BlogSidebarComponent,
        InnerNewsletterComponent,
        CookiesComponent
    ]
})
export class SharedModule { }
